import React from 'react'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import useScreenLog from '@hook/useScreenLog'
import TokenLandingPageContainer from '@feature/landing/pages/token/TokenLandingPageContainer'

const HomePage: React.FC = () => {
    const {} = useScreenLog({pageName: 'home'})

    return <TokenLandingPageContainer />
}

export const getServerSideProps = async ctx => {
    const cookies = ctx.req.cookies
    const locale = ctx.locale
    const loginId = cookies.userId ?? ''
    if (loginId) {
        return {
            redirect: {
                permanent: false,
                destination: `/${locale}/leaderboard`,
            },
        }
    } else {
        return {
            props: {
                ...(await serverSideTranslations(ctx.locale, ['common'])),
            },
        }
    }
}

export default HomePage
